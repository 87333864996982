define("discourse/plugins/discourse-events/discourse/components/events-calendar-subscription-row", ["exports", "@ember/component", "@ember/runloop", "jquery", "discourse/lib/copy-text", "select-kit/components/dropdown-select-box/dropdown-select-box-row", "@ember/template-factory"], function (_exports, _component, _runloop, _jquery, _copyText, _dropdownSelectBoxRow, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.copiedUrl}}
    <span class="copied">{{i18n "copy_codeblock.copied"}}</span>
  {{/if}}
  <span class="name">{{this.label}}</span>
  {{input value=this.item.id}}
  */
  {
    "id": "5x1Df4bg",
    "block": "[[[41,[30,0,[\"copiedUrl\"]],[[[1,\"  \"],[10,1],[14,0,\"copied\"],[12],[1,[28,[35,2],[\"copy_codeblock.copied\"],null]],[13],[1,\"\\n\"]],[]],null],[10,1],[14,0,\"name\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"],[1,[28,[35,3],null,[[\"value\"],[[30,0,[\"item\",\"id\"]]]]]]],[],false,[\"if\",\"span\",\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/events-calendar-subscription-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _dropdownSelectBoxRow.default.extend({
    layoutName: "discourse/templates/components/events-calendar-subscription-row",
    classNames: "events-calendar-subscription-row",
    click() {
      const $copyRange = (0, _jquery.default)('<p id="copy-range"></p>');
      $copyRange.html(this.item.id);
      (0, _jquery.default)(document.body).append($copyRange);
      if ((0, _copyText.default)(this.item.id, $copyRange[0])) {
        this.set("copiedUrl", true);
        (0, _runloop.later)(() => {
          if (!this.element || this.isDestroying || this.isDestroyed) {
            return;
          }
          this.set("copiedUrl", false);
        }, 2000);
      }
      $copyRange.remove();
    }
  }));
});