define("discourse/plugins/discourse-events/discourse/templates/preferences/webcal-keys", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="control-label">{{i18n "webcal_preferences.heading"}}</label>
  <EventWebcalKeys />
  */
  {
    "id": "ANBYda4x",
    "block": "[[[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"webcal_preferences.heading\"],null]],[13],[1,\"\\n\"],[8,[39,2],null,null,null]],[],false,[\"label\",\"i18n\",\"event-webcal-keys\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/preferences/webcal-keys.hbs",
    "isStrictMode": false
  });
});