define("discourse/plugins/discourse-events/discourse/components/events-subscription-selector/events-subscription-selector-row", ["exports", "@ember/component", "discourse-common/utils/decorators", "select-kit/components/select-kit/select-kit-row", "@ember/template-factory"], function (_exports, _component, _decorators, _selectKitRow, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.icons}}
    <div class="icons">
      <span class="selection-indicator"></span>
      {{#each this.icons as |icon|}}
        {{d-icon icon translatedtitle=(dasherize this.title)}}
      {{/each}}
    </div>
  {{/if}}
  
  <div class="texts">
    <span class="name">{{html-safe this.label}}</span>
    {{#if this.item.subscriptionRequired}}
      <span class="subscription-label">{{i18n this.item.selectorLabel}}</span>
    {{/if}}
  </div>
  */
  {
    "id": "baaa7ZsS",
    "block": "[[[41,[30,0,[\"icons\"]],[[[1,\"  \"],[10,0],[14,0,\"icons\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"selection-indicator\"],[12],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"icons\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,5],[[30,1]],[[\"translatedtitle\"],[[28,[37,6],[[30,0,[\"title\"]]],null]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"texts\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"name\"],[12],[1,[28,[35,7],[[30,0,[\"label\"]]],null]],[13],[1,\"\\n\"],[41,[30,0,[\"item\",\"subscriptionRequired\"]],[[[1,\"    \"],[10,1],[14,0,\"subscription-label\"],[12],[1,[28,[35,8],[[30,0,[\"item\",\"selectorLabel\"]]],null]],[13],[1,\"\\n\"]],[]],null],[13]],[\"icon\"],false,[\"if\",\"div\",\"span\",\"each\",\"-track-array\",\"d-icon\",\"dasherize\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/events-subscription-selector/events-subscription-selector-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _selectKitRow.default.extend(dt7948.p({
    classNameBindings: ["isDisabled:disabled"],
    isDisabled() {
      return this.item.disabled;
    },
    click(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.item.disabled) {
        this.selectKit.select(this.rowValue, this.item);
      }
      return false;
    }
  }, [["method", "isDisabled", [(0, _decorators.default)("item")]]])));
});