define("discourse/plugins/discourse-events/discourse/templates/connectors/topic-title/event-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless @outletArgs.model.editingTopic}}
    {{#if @outletArgs.model.event}}
      <div class="topic-event-label">
        {{event-label @outletArgs.model.event topic="true"}}
        {{#if this.siteSettings.events_add_to_calendar}}
          <AddToCalendar @topic={{@outletArgs.model}} />
        {{/if}}
      </div>
      {{#if @outletArgs.model.event_record.video_url}}
        <EventVideoBtn @videoUrl={{@outletArgs.model.event_record.video_url}} />
      {{/if}}
      {{#if this.showRsvp}}
        <EventRsvp @topic={{@outletArgs.model}} />
      {{/if}}
    {{/if}}
  {{/unless}}
  */
  {
    "id": "zJi2zyJN",
    "block": "[[[41,[51,[30,1,[\"model\",\"editingTopic\"]]],[[[41,[30,1,[\"model\",\"event\"]],[[[1,\"    \"],[10,0],[14,0,\"topic-event-label\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[[30,1,[\"model\",\"event\"]]],[[\"topic\"],[\"true\"]]]],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"events_add_to_calendar\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@topic\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[41,[30,1,[\"model\",\"event_record\",\"video_url\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@videoUrl\"],[[30,1,[\"model\",\"event_record\",\"video_url\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showRsvp\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@topic\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[\"@outletArgs\"],false,[\"unless\",\"if\",\"div\",\"event-label\",\"add-to-calendar\",\"event-video-btn\",\"event-rsvp\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/connectors/topic-title/event-details.hbs",
    "isStrictMode": false
  });
});