define("discourse/plugins/discourse-events/discourse/components/event-webcal-keys", ["exports", "@ember/component", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.apiKey}}
    <div class="events-webcal-api">
      <label class="webcal-label">{{i18n
          "webcal_preferences.api_key"
        }}</label><pre>{{this.apiKey}}</pre>
      <label class="webcal-label">{{i18n
          "webcal_preferences.client_id"
        }}</label><pre>{{this.clientID}}</pre>
      <p class="webcal-notice">{{i18n "webcal_preferences.notice"}}</p>
    </div>
  {{/if}}
  <DButton
    @action={{action "show"}}
    class="btn-primary"
    @label="webcal_preferences.generate"
  />
  */
  {
    "id": "Wo8rQ6+A",
    "block": "[[[41,[30,0,[\"apiKey\"]],[[[1,\"  \"],[10,0],[14,0,\"events-webcal-api\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"webcal-label\"],[12],[1,[28,[35,3],[\"webcal_preferences.api_key\"],null]],[13],[10,\"pre\"],[12],[1,[30,0,[\"apiKey\"]]],[13],[1,\"\\n    \"],[10,\"label\"],[14,0,\"webcal-label\"],[12],[1,[28,[35,3],[\"webcal_preferences.client_id\"],null]],[13],[10,\"pre\"],[12],[1,[30,0,[\"clientID\"]]],[13],[1,\"\\n    \"],[10,2],[14,0,\"webcal-notice\"],[12],[1,[28,[35,3],[\"webcal_preferences.notice\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[8,[39,6],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,7],[[30,0],\"show\"],null],\"webcal_preferences.generate\"]],null]],[],false,[\"if\",\"div\",\"label\",\"i18n\",\"pre\",\"p\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/event-webcal-keys.hbs",
    "isStrictMode": false
  });
  const KEY_ENDPOINT = "/discourse-events/api-keys.json";
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    actions: {
      show() {
        (0, _ajax.ajax)(KEY_ENDPOINT, {
          type: "GET"
        }).then(result => {
          this.set("apiKey", result["api_keys"][0]["key"]);
          this.set("clientID", result["api_keys"][0]["client_id"]);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }));
});