define("discourse/plugins/discourse-events/discourse/components/modal/events-filters", ["exports", "@ember/component", "@ember/array", "@ember/object/computed", "discourse/plugins/discourse-events/discourse/models/filter", "@ember/template-factory"], function (_exports, _component, _array, _computed, _filter, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="events-filters-modal"
    @closeModal={{@closeModal}}
    @title={{i18n "admin.events.filters.label"}}
  >
    <:body>
      {{#if this.hasFilters}}
        <table class="filters">
          <thead>
            <th>{{i18n "admin.events.filter.query_column.label"}}</th>
            <th>{{i18n "admin.events.filter.query_operator.label"}}</th>
            <th>{{i18n "admin.events.filter.query_value.label"}}</th>
            <th></th>
          </thead>
          <tbody>
            {{#each this.model.filters as |filter|}}
              <EventFilterRow
                @filter={{filter}}
                @removeFilter={{action "removeFilter"}}
              />
            {{/each}}
          </tbody>
        </table>
      {{/if}}
      <DButton
        @action={{action "addFilter"}}
        @icon="plus"
        class="add-filter"
        @label="admin.events.filters.add.label"
        @title="admin.events.filters.add.title"
      />
    </:body>
    <:footer>
      <DButton
        class="btn-primary"
        @action={{@closeModal}}
        @label="admin.events.filters.done.label"
        @title="admin.events.filters.done.title"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "JTFKiaCw",
    "block": "[[[8,[39,0],[[24,0,\"events-filters-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"admin.events.filters.label\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasFilters\"]],[[[1,\"      \"],[10,\"table\"],[14,0,\"filters\"],[12],[1,\"\\n        \"],[10,\"thead\"],[12],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,1],[\"admin.events.filter.query_column.label\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,1],[\"admin.events.filter.query_operator.label\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,1],[\"admin.events.filter.query_value.label\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,0,[\"model\",\"filters\"]]],null]],null],null,[[[1,\"            \"],[8,[39,10],null,[[\"@filter\",\"@removeFilter\"],[[30,2],[28,[37,11],[[30,0],\"removeFilter\"],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,12],[[24,0,\"add-filter\"]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[28,[37,11],[[30,0],\"addFilter\"],null],\"plus\",\"admin.events.filters.add.label\",\"admin.events.filters.add.title\"]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,12],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@title\"],[[30,1],\"admin.events.filters.done.label\",\"admin.events.filters.done.title\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"filter\"],false,[\"d-modal\",\"i18n\",\":body\",\"if\",\"table\",\"thead\",\"th\",\"tbody\",\"each\",\"-track-array\",\"event-filter-row\",\"action\",\"d-button\",\":footer\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/modal/events-filters.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    hasFilters: (0, _computed.notEmpty)("model.filters"),
    didInsertElement() {
      this._super(...arguments);
      if (!this.model.filters) {
        this.model.set("filters", (0, _array.A)());
      }
    },
    actions: {
      addFilter() {
        const filter = _filter.default.create({
          id: "new"
        });
        this.model.get("filters").pushObject(filter);
      },
      removeFilter(filter) {
        this.model.get("filters").removeObject(filter);
      }
    }
  }));
});