define("discourse/plugins/discourse-events/discourse/components/events-log-table", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "discourse/plugins/discourse-events/discourse/models/log", "@ember/template-factory"], function (_exports, _component, _component2, _object, _log, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LoadMore
    @selector=".directory-table .directory-table__cell"
    @action={{action "loadMore"}}
  >
    <ResponsiveTable @className="events-log-table">
      <:header>
        <TableHeaderToggle
          @field="created_at"
          @labelKey="admin.events.log.created_at"
          @order={{@order}}
          @asc={{@asc}}
          @automatic={{true}}
        />
        <TableHeaderToggle
          @field="level"
          @labelKey="admin.events.log.level"
          @order={{@order}}
          @asc={{@asc}}
          @automatic={{true}}
        />
        <TableHeaderToggle
          @field="context"
          @labelKey="admin.events.log.context"
          @order={{@order}}
          @asc={{@asc}}
          @automatic={{true}}
        />
        <div class="directory-table__column-header log-message">
          {{I18n "admin.events.log.message"}}
        </div>
      </:header>
      <:body>
        {{#each @logs as |log|}}
          <EventsLogRow @log={{log}} />
        {{/each}}
      </:body>
    </ResponsiveTable>
  </LoadMore>
  <ConditionalLoadingSpinner @condition={{this.loading}} />
  */
  {
    "id": "931ryaOu",
    "block": "[[[8,[39,0],null,[[\"@selector\",\"@action\"],[\".directory-table .directory-table__cell\",[28,[37,1],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@className\"],[\"events-log-table\"]],[[\"header\",\"body\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@field\",\"@labelKey\",\"@order\",\"@asc\",\"@automatic\"],[\"created_at\",\"admin.events.log.created_at\",[30,1],[30,2],true]],null],[1,\"\\n      \"],[8,[39,4],null,[[\"@field\",\"@labelKey\",\"@order\",\"@asc\",\"@automatic\"],[\"level\",\"admin.events.log.level\",[30,1],[30,2],true]],null],[1,\"\\n      \"],[8,[39,4],null,[[\"@field\",\"@labelKey\",\"@order\",\"@asc\",\"@automatic\"],[\"context\",\"admin.events.log.context\",[30,1],[30,2],true]],null],[1,\"\\n      \"],[10,0],[14,0,\"directory-table__column-header log-message\"],[12],[1,\"\\n        \"],[1,[28,[35,6],[\"admin.events.log.message\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,3]],null]],null],null,[[[1,\"        \"],[8,[39,10],null,[[\"@log\"],[[30,4]]],null],[1,\"\\n\"]],[4]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,11],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],null]],[\"@order\",\"@asc\",\"@logs\",\"log\"],false,[\"load-more\",\"action\",\"responsive-table\",\":header\",\"table-header-toggle\",\"div\",\"I18n\",\":body\",\"each\",\"-track-array\",\"events-log-row\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/events-log-table.hbs",
    "isStrictMode": false
  });
  class EventsLogTable extends _component2.default {
    loading = false;
    loadingComplete = false;
    page = 0;
    loadMore() {
      if (this.loading || this.loadingComplete) {
        return;
      }
      let page = this.page + 1;
      this.page = page;
      this.loading = true;
      _log.default.list({
        page
      }).then(result => {
        if (result.logs && result.logs.length) {
          this.args.logs.pushObjects(result.logs.map(p => _log.default.create(p)));
        } else {
          this.loadingComplete = true;
        }
      }).finally(() => {
        this.loading = false;
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = EventsLogTable;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EventsLogTable);
});